import React, { useState } from "react";
import { GalleryHeader } from "./GalleryHeader";
import { GalleryWrapper } from "./GalleryWrapper";
import { BackToTop } from "../BackTotop";
import { Theme } from "../Theme";
function GalleryPage() {
  const [Inter, setInter] = useState({ state: true });
  return (
    <>
      <main className="gallery--main" id="gallery--home">
        <GalleryHeader setInter={setInter} />
        <GalleryWrapper />
        <div className="theme--and--top">
          {!Inter.state ? <BackToTop id="gallery--home" /> : ""}
          <Theme />
        </div>
      </main>
    </>
  );
}

export { GalleryPage };
