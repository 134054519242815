import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HeroImage } from "../homepage/HeroImage";
import { motion } from "framer-motion";
import { container, item } from "../animation";
function GalleryHeaderImages() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGalleryHeadImages {
        nodes {
          id
          galleryHead {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  `);

  const {
    allContentfulGalleryHeadImages: { nodes },
  } = data;
  return (
    <>
      <div className="header--images--wrapper">
        <motion.ul variants={container} animate="visible" initial="hidden">
          {nodes.map((n) => {
            return (
              <motion.li key={n.id} variants={item}>
                <HeroImage
                  classId="hero--image"
                  image={n.galleryHead}
                  alt="teeth illustrations"
                />
              </motion.li>
            );
          })}
        </motion.ul>
      </div>
    </>
  );
}

export { GalleryHeaderImages };
