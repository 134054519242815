import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HeroText } from "../homepage/HeroText";
import { HeroImage } from "../homepage/HeroImage";
import { GalleryHeaderImages } from "./GalleryHeaderImages";
function GalleryHeroContainer() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGalleryHeader {
        nodes {
          title
          body
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const {
    allContentfulGalleryHeader: {
      nodes: [{ title, body, headerImage }],
    },
  } = data;

  return (
    <>
      <div className="hero--container">
        <HeroText
          title={title}
          body={body}
          goto="route"
          value="Route details"
          id="header--link3"
        />
        <HeroImage
          image={headerImage}
          classId="hero-image"
          alt="teeth illustration"
        />
        <GalleryHeaderImages />
      </div>
    </>
  );
}

export { GalleryHeroContainer };
