import React from "react";
import { GalleryTop } from "./GalleryTop";
import { ShowCase } from "./ShowCase";
import { ShowCase2 } from "./ShowCase2";
function GalleryWrapper() {
  return (
    <>
      <div className="gallery--wrapper">
        <GalleryTop />
        <div className="showCase--wrapper">
          <ShowCase />
          <ShowCase2 />
        </div>
      </div>
    </>
  );
}

export { GalleryWrapper };
