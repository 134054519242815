import * as React from "react";
import { GalleryPage } from "../Components/Gallery/GalleryPage";
import { Footer } from "../Components/Footer";
function gallery() {
  return (
    <>
      <GalleryPage />
      <Footer />
    </>
  );
}

export default gallery;
