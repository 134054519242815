import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image } from "../Image";

function ShowCase() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGallery {
        nodes {
          id
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const {
    allContentfulGallery: { nodes },
  } = data;

  return (
    <>
      <div className="show--case">
        <ul>
          {nodes.map((n) => {
            return (
              <li key={n.id}>
                <Image
                  classId="show--case--images"
                  image={n.image}
                  alt="treatment before and after"
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export { ShowCase };
