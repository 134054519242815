import React from "react";

function GalleryTop() {
  return (
    <>
      <div className="gallery--top">
        <h2>Showcase</h2>
        <p>
          some of our esthetic correction procedures to make confident smile.
          Some of the Images may be disturbing
        </p>
      </div>
    </>
  );
}

export { GalleryTop };
